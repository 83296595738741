import React,{forwardRef,useEffect, useState,useRef,useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { useForm } from "react-hook-form";

import {DataTable,ApplicationViewCard, PDFViewCard} from '../../Components';
import {IdentifyTypeName,PrefixName} from '../../Utils/Prefix';
import {DateThaiFormat} from '../../Utils/Function';
import Swal from 'sweetalert2';
import API from '../../API';
import '../../App.css';

const ApplicantView = forwardRef((props,ref) =>{
    const { register, getValues, setValue } = useForm();

    const RedirectTo = useNavigate()
    const _ApplicantView = useRef();
    const _PDFViewCard = useRef();
    const _ApplicationViewCard = useRef();
    const [_RegRoundList,setRegRoundList] = useState([]);

    useEffect(() => {
        (async () => {
        })();

        return ()=>{ }
    }, []);

    const _configs = {
        "order": [[5, "desc"]],
        pageLength: 25,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Staff/Applicant/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            //d.Status = getValues('Status');
            //d.RegRoundID = getValues('RegRoundID');
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() =>{_ApplicationViewCard.current?.Show(d)}}>Info</button>
        </div>
    )
    const _column = [
        { data: "MobileNumber", name: "MobileNumber", autoWidth: true },
        { data: "IdentifyType", name: "IdentifyType", autoWidth: true,render(data){
            return IdentifyTypeName(data);
        }},
        { data: "IdentifyId", name: "IdentifyID", autoWidth: true },
        { data: "FirstName", name: "FirstName", autoWidth: true,render(data,i,row){
            return PrefixName(row.PrefixNameId)+row.FirstName+' '+row.LastName;
        }},
        { data: "Email", name: "Email", autoWidth: true },
        { data: "CreateDate", name: "CreateDate", autoWidth: true,render(data){ return DateThaiFormat(data,true);} },
        { data: "MobileVerifly", name: "MobileVerifly", autoWidth: true,render(data){ return DateThaiFormat(data,true);} },
        {
            data: "ApplicantId", name: "ApplicantID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _Update = ()=>{
        _ApplicantView.current?.load()
    }
    return (
        <>
        <React.Fragment>
            <div className='row'>
                    <div className='col-lg-12 mt-2'>
                        <div className='row mb-4'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <div className='card-title fs-5 fw-semibold'> 
                                            <div className='row'>
                                                <div className='col-lg-8'><h4 className='text-start mt-2'>รายการลงทะเบียน</h4></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row mt-2 mb-2'>
                                            <div className='col-12 table-responseive'>
                                                <table className='table' id="ApplicantView">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center textcenter'>เบอร์โทรศัพท์</th>
                                                            <th className='text-center textleft'>ประเภทบัตรประจำตัว</th>
                                                            <th className='text-center textleft'>เลขบัตรประจำตัว</th>
                                                            <th className='text-center textleft'>ชื่อ-นามสกุล</th>
                                                            <th className='text-center textcenter'>Email</th>
                                                            <th className='text-center textcenter'>วันที่สมัคร</th>
                                                            <th className='text-center textcenter'>วันที่ยืนยัน</th>
                                                            <th className='text-center textcenter no-sort'>#</th>
                                                        </tr>
                                                    </thead>
                                                    <DataTable TableID={"ApplicantView"} Column={_column} Configs={_configs} ref={_ApplicantView} ajax={_ajax} ColumnDefs={_ColumnDefs} />

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>
        </React.Fragment>
        </>
    )
});
export default React.memo(ApplicantView)
import React,{forwardRef,useEffect, useState,useRef,useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import '../../App.css';
import JoditEditor from 'jodit-react';

const AnnounceContent = forwardRef((props,ref) =>{
    const RedirectTo = useNavigate()
    const [_Content ,setContent] = useState('');
    const editor = useRef(null);
    const inputFile = useRef();
    useEffect(() => {
        (async () => {
            _Announce();
        })();

        return ()=>{ }
    }, []);


    const _Announce = async()=>{
        API({method : "get",url:"/Staff/Announce"}).then(res=>{
            setContent(res.data);
        }).catch(error=>{

        });
    }

    const _Save = async() =>{

        API({method : "post",url:"/Staff/Announce",data:_Content,headers: { "Content-Type": "multipart/form-data" }}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });   
                return;
            }
            Swal.fire({
                icon: 'success',
                title: res.data.Message,
            });

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: (res.response != undefined?res.response.data.Message:'การเชื่อมต่อผิดพลาด'),
                text: res.message+' ['+res.code+']',
            });

            return;
        });
    }
    const _Upload = () =>{
        inputFile.current.value = null;
        inputFile.current.click();
    }
    const _UploadFileAction = async(e)=>{
        console.log(inputFile.current.files[0]);
        const fd = new FormData();
		fd.append("File", inputFile.current.files[0]);
        API({method : "post",url:'/File/Announce',data:fd,headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            Swal.fire({
                icon: 'success',
                title: res.data.url,
            });
        }).catch(error => {
            console.log("Server Error : ", error);
        });
    }
    return (
        <>
            <div className='row justify-content-center'>
                    <div className='col-lg-10 col-12 mt-2'>
                        <div className='row mb-4'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <div className='card-title fs-5 fw-semibold'> 
                                            <div className='row'>
                                                <div className='col-lg-8'><h4 className='text-start mt-2'>ข่าวประชาสัมพันธ์</h4></div>
                                                <div className='col-lg-4  text-end'>
                                                    <div className='btn-group'>
                                                        <button type='button' className='btn btn-success' onClick={()=>{_Save();}}>บันทึก</button>
                                                        <button type='button' className='btn btn-danger' onClick={()=>{_Upload();}}>Upload</button>

                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row mt-2 mb-2'>
                                            <div className='col-12'>
                                            <JoditEditor
                                                ref={editor}
                                                value={_Content}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                                onChange={(newContent) => {setContent(newContent)}}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
            </div>
            <input type='file' id='file' onChange={(e)=>{_UploadFileAction(e)}} ref={inputFile} style={{display: 'none'}}/>

        </>
    )
});
export default React.memo(AnnounceContent)
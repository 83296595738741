import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../API'
import Swal from 'sweetalert2';

const PageLogin =()=> {
    const RedirectTo = useNavigate();
    const [_Username,setUsername] = useState('');
    const [_Password,setPassword] = useState('');

    const _Login = async() =>{
        const _reqObj = {
            Username: _Username,
            Password:_Password
        }
        Swal.showLoading();
        sessionStorage.clear();
        API({method : "post",url:"/Staff/Login",data:_reqObj}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                
                return;
            }
            sessionStorage.setItem("Authorization", res.data.Response.Authorization);
            sessionStorage.setItem("StaffID", res.data.Response.StaffID);
            sessionStorage.setItem("StaffProfile",JSON.stringify(res.data.Response) );
            sessionStorage.setItem("StaffName",res.data.Response.Name);

            Swal.fire({
                icon: 'success',
                title: 'เข้าระบบสำเร็จ',
                text: 'โปรดกรุณารอสักครู่',
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false
            }).then(()=>{
                //RedirectTo("/");
                window.location.reload();
            });

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: (res.response != undefined?res.response.data.Message:'การเชื่อมต่อผิดพลาด'),
                text: res.message+' ['+res.code+']',
            });

            return;
        });
    }
    return (
        <>
        <React.Fragment>
            
            <div className='container'>
                <div className='row mt-4 justify-content-center'>
                    <div className='col-lg-4 text-center'>
                        <div className='card'>
                            <div className='card-header text-start'>
                                <h4 className='card-title'>เข้าสู่ระบบ</h4>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className="mb-2 text-start">
                                        <label className="form-label fw-semibold">Username :</label>
                                        <input type="text" className="form-control text-center" value={_Username} onChange={(e)=>{setUsername(e.target.value);}} />
                                    </div>
                                    <div className="mb-2 text-start">
                                        <label className="form-label fw-semibold">Password :</label>
                                        <input type="password" className="form-control text-center" value={_Password} onChange={(e)=>{setPassword(e.target.value);}} />
                                    </div>
                                    <div className="mt-3 mb-3 text-center">
                                        <button type='button' className='btn btn-success btn-lg' onClick={()=>{_Login();}}>เข้าสู่ระบบ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageLogin)

import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { PrefixName,IdentifyTypeName } from '../../Utils/Prefix';
import { DateThaiFormat } from '../../Utils/Function';
const ApplicationForm = forwardRef((props,ref) =>{
    const _Detail = props.Profile;
    useEffect(() => {
        (async () => {
        })();

        return ()=>{ }
    }, []);
    if(_Detail != null){
        return (
            <>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-lg-2'>
                                        <label className="form-label fw-semibold">คำนำหน้าชื่อ</label>
                                        <div className='form-control text-center'>{PrefixName(_Detail.PrefixNameID)}</div>
                                    </div>
                                    <div className='col-lg-5'>
                                        <label className="form-label fw-semibold">ชื่อ</label>
                                        <div className='form-control text-first'>{_Detail.FirstName}</div>

                                    </div>
                                    <div className=' col-lg-5'>
                                        <label className="form-label fw-semibold">นามสกุล</label>
                                        <div className='form-control text-first'>{_Detail.LastName}</div>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-2'>
                                        <label className="form-label fw-semibold">วันเดือนปีเกิด</label>
                                        <div className='form-control text-center'>{DateThaiFormat(_Detail.BirthDay)}</div>
                                    </div>
                                    <div className='col-2'>
                                        <label className="form-label fw-semibold">อายุ</label>
                                        <div className='form-control text-center'>{_Detail.Age}</div>
                                    </div>
                                    <div className='col-3'>
                                        <label className="form-label fw-semibold">{IdentifyTypeName(_Detail.IdentifyType)}</label>
                                        <div className='form-control text-center'>{_Detail.IdentifyID}</div>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label className="form-label fw-semibold">ที่อยู่ปัจจุบัน</label>
                                        <div className='form-control'>{_Detail.Address}</div>
                                    </div>
                                </div>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-6'>
                                        <label className="form-label fw-semibold">ตำบล / อำเภอ / จังหวัด</label>
                                        <div className='form-control'>{_Detail.District.FullName}</div>
                                    </div>
                                    <div className='col-2'>
                                        <label className="form-label fw-semibold">รหัสไปรษณีย์</label>
                                        <div className='form-control'>{_Detail.PostCode}</div>
                                    </div>
                                </div>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-4'>
                                        <label className="form-label fw-semibold">หมายเลขโทรศัพท์นักเรียน</label>
                                        <div className='form-control'>{_Detail.Mobile}</div>
                                    </div>
                                    <div className='col-4'>
                                        <label className="form-label fw-semibold">หมายเลขโทรศัพท์ผู้ปกครอง</label>
                                        <div className='form-control'>{_Detail.ParentMobile}</div>
                                    </div>
                                </div>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-8'>
                                        {(_Detail.RegType === 1 ?<label className="form-label fw-semibold">กำลังศึกษาชั้นประถมศึกษาปีที่ 6 โรงเรียน</label> :"")}
                                        {(_Detail.RegType === 2 ?<label className="form-label fw-semibold">กำลังศึกษาชั้นมัธยมศึกษาปีที่ 3 โรงเรียน</label> :"")}
                                        <div className='form-control'>{_Detail.SchoolName}</div>
                                    </div>
                                    <div className='col-4'>
                                    <label className="form-label fw-semibold">อำเภอ / จังหวัด</label>
                                        <div className='form-control text-center'>อ.{_Detail.SchoolCityName} จ.{_Detail.SchoolProvinceName}</div>
                                    </div>
                                </div>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label className="form-label fw-semibold">ประเภทโครงการ</label>
                                        <div className='form-control'>{_Detail.ProjectName}</div>
                                    </div>
                                </div>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-3'>
                                        <label className="form-label fw-semibold">เกรดเฉลี่ย (GPAX)</label>
                                        <div className='form-control text-center'>{_Detail.GPA}</div>
                                    </div>
                                    <div className='col-3'>
                                        <label className="form-label fw-semibold">เกรดเฉลี่ย (ภาษาอังกฤษ)</label>
                                        <div className='form-control text-center'>{(_Detail.GPAEnglish != null ? _Detail.GPAEnglish:'-')}</div>
                                    </div>
                                    <div className='col-3'>
                                        <label className="form-label fw-semibold">เกรดเฉลี่ย (คณิตศาสตร์)</label>
                                        <div className='form-control text-center'>{(_Detail.GPAMath != null ? _Detail.GPAMath:'-')}</div>
                                    </div>
                                    <div className='col-3'>
                                        <label className="form-label fw-semibold">เกรดเฉลี่ย (วิทยาศาสตร์)</label>
                                        <div className='form-control text-center'>{(_Detail.GPAScience != null ? _Detail.GPAScience:'-')}</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    
});

export default React.memo(ApplicationForm)
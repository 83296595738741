import React,{} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {IsLogged} from '../Utils/Function';

import '../App.css';
const Header =()=> {

    const Logout = async()=>{
        sessionStorage.clear();
        window.location.href ="/";
    }
    return (
        <>
        <header>
            <Navbar collapseOnSelect expand="lg" className="navbar navbar-light header fixed-top" style={{backgroundColor:'#073487'}} >
                <Container fluid>
                    <Navbar.Brand href="" style={{'padding':'0px 0px 0px 0px'}}>
                            <img className='navbar-brand' src={require('../Assets/Images/Wattanothai_Logo.png')} style={{'height':'65px','float':'left'}} alt='Logo'/>
                            <div className='text-left text-white d-block d-sm-block d-md-none ibm-plex-sans-thai-looped-extraligh' style={{'float':'left','paddingTop':'14px','fontSize':'12px'}}>งานรับนักเรียน <br/>โรงเรียนวัฒโนทัยพายัพ จังหวัดเชียงใหม่</div>
                            <div className='text-left text-white d-none d-md-block d-lg-block d-xl-block d-xxl-block ibm-plex-sans-thai-looped-extraligh' style={{'float':'left','paddingTop':'18px','fontSize':'20px'}}>งานรับนักเรียน โรงเรียนวัฒโนทัยพายัพ จังหวัดเชียงใหม่</div>
                    </Navbar.Brand>
                    {(IsLogged()?
                        <Navbar.Collapse>
                            <Nav className="me-auto">
                                <Nav.Link href="#link">
                                <div className='text-end text-white'></div>
                                </Nav.Link>
                            </Nav>
                            <Nav style={{'marginRight':'100px'}}>
                                <Nav.Item className='text-end text-white mt-4' style={{'marginRight':'20px'}}>{sessionStorage.getItem('StaffName')}</Nav.Item>
                                <NavDropdown title={(<img src={require('../Assets/Images/Avatar/avatar.png')} className='avatar' alt='Avatar'/>)}>
                                    <NavDropdown.Item href="/">หน้าหลัก</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/Announce">ข่าวประชาสัมพันธ์</NavDropdown.Item>
                                    <NavDropdown.Item href="/Application">รายการใบสมัคร</NavDropdown.Item>
                                    <NavDropdown.Item href="/Applicant">รายการลงทะเบียน</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={()=>{}}>เปลี่ยนรหัสผ่าน</NavDropdown.Item>
                                    <NavDropdown.Item onClick={()=>{Logout();}}>
                                        ออกจากระบบ
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    :'')}
                </Container> 
            </Navbar>
        </header>
        </>
    )
}

export default Header;


import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import ApplicationView from './ApplicationView';
import '../../App.css';
const PageHome =()=> {
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);

    return (
        <>
        <React.Fragment>
            <div className='container-fluuid'>
                <div className='row'>
                    <div className='col-12'>
                    <ApplicationView/>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageHome)

import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import AnnounceContent from './AnnounceContent';
import '../../App.css';
const PageAnnounce =()=> {
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    return (
        <>
        <React.Fragment>
            <div className='container-fluuid'>
                <div className='row'>
                    <div className='col-12'>
                    <AnnounceContent/>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageAnnounce)

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageLogin from './Pages/PageLogin';
import {IsLogged} from './Utils/Function';
import { PageHome,PageNotFound,PageAnnounce,PageApplicant} from './Pages';
const Router =() =>{
    if(IsLogged() === true){
        return (
            <Routes>
                <Route path="/" element={<PageHome/>}/>
                <Route path="/Application" element={<PageHome/>}/>
                <Route path="/Announce" element={<PageAnnounce/>}/>
                <Route path="/Applicant" element={<PageApplicant/>}/>
                <Route path="*" element={<PageNotFound/>} />
            </Routes>
        )
    }else{
        return (
            <Routes>
                <Route path="*" element={<PageLogin/>} />
            </Routes>
        )
    }
    
}

export default React.memo(Router)
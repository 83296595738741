import React,{forwardRef,useEffect, useState,useRef,useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { useForm } from "react-hook-form";

import {DataTable,ApplicationViewCard, PDFViewCard} from '../../Components';
import {IdentifyTypeName} from '../../Utils/Prefix';
import {DateThaiFormat} from '../../Utils/Function';
import Swal from 'sweetalert2';
import API from '../../API';
import '../../App.css';

const ApplicationView = forwardRef((props,ref) =>{
    const { register, getValues, setValue } = useForm();

    const RedirectTo = useNavigate()
    const _ApplicationFormView = useRef();
    const _PDFViewCard = useRef();
    const _ApplicationViewCard = useRef();
    const [_RegRoundList,setRegRoundList] = useState([]);
    const [_ProjectList,setProjectList] = useState([]);

    useEffect(() => {
        (async () => {
            _LoadRegRoundList();
            _LoadProjectList();
        })();

        return ()=>{ }
    }, []);
    const _LoadRegRoundList = async()=>{
        API({method : "get",url:"/Search/RegRound"}).then(res=>{
            if(res.data.Status){
                setRegRoundList(res.data.Response);
            }
        }).catch(error=>{

        });
    }
    const _LoadProjectList = async()=>{
        API({method : "get",url:"/Search/Project"}).then(res=>{
            if(res.data.Status){
                setProjectList(res.data.Response);
            }
        }).catch(error=>{

        });
    }
    const _configs = {
        "order": [[8, "desc"]],
        pageLength: 25,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Staff/Application/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            d.Status = getValues('Status');
            d.RegRoundID = getValues('RegRoundID');
            d.ProjectID = getValues('ProjectID');
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() =>{_ApplicationViewCard.current?.Show(d)}}>Info</button>
        </div>
    )
    const _column = [
        { data: "CandidateCode", name: "CandidateCode", autoWidth: true },
        {data: "IdentifyType", name: "IdentifyType", autoWidth: true,render(data){
            return IdentifyTypeName(data);
        }},
        { data: "IdentifyID", name: "IdentifyID", autoWidth: true },
        {data: "FirstName", name: "FirstName", autoWidth: true,render(data,i,row){
            return row.FirstName+' '+row.LastName;
        }},
        { data: "Mobile", name: "Mobile", autoWidth: true },
        { data: "RegTypeName", name: "RegTypeName", autoWidth: true },
        { data: "RegRoundName", name: "RegRoundName", autoWidth: true },
        { data: "StatusName", name: "StatusName", autoWidth: true },
        { data: "SubmitDate", name: "SubmitDate", autoWidth: true,render(data){ return DateThaiFormat(data,true);} },
        { data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){ return DateThaiFormat(data,true);} },
        {
            data: "RegulationID", name: "RegulationID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _Update = ()=>{
        _ApplicationFormView.current?.load()
    }
    const _Export = ()=>{
        Swal.showLoading();
        API({method : "post",url:"/Staff/Application/Export",data:{Status:getValues('Status'),RegRoundID:getValues('RegRoundID'),ProjectID:getValues('ProjectID')},responseType:'blob',headers:{'Content-Type': 'application/x-www-form-urlencoded'}}).then(res=>{
            let _fileName = res.request.getResponseHeader('Content-Disposition').split(';')[1].split('=')[1];
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res.data);
            link.download = _fileName;
            link.click();
            Swal.close();
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='row'>
                    <div className='col-lg-12 mt-2'>
                        <div className='row mb-4'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <div className='card-title fs-5 fw-semibold'> 
                                            <div className='row'>
                                                <div className='col-lg-8'><h4 className='text-start mt-2'>รายการใบสมัคร</h4></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row mt-2 mb-2 justify-content-end'>
                                            <div className='col-lg-4'>
                                                <select className='form-control' {...register("RegRoundID", { required: false })} defaultValue={null}>
                                                    <option value={null}>แสดงทั้งหมด</option>
                                                    {_RegRoundList.map((v,i)=>(
                                                        <option value={v.RegRoundID} key={i}>{v.Name} {v.RegTypeName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-lg-4'>
                                                <select className='form-control' {...register("ProjectID", { required: false })} defaultValue={null}>
                                                    <option value={null}>แสดงทั้งหมด</option>
                                                    {_ProjectList.map((v,i)=>(
                                                        <option value={v.ProjectID} key={i}>{v.ProjectName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-lg-2'>
                                                <select className='form-control' {...register("Status", { required: false })} defaultValue={11}>
                                                    <option value={0}>แสดงทั้งหมด</option>
                                                    <option value={1}>กรอกใบสมัครสอบ</option>
                                                    <option value={2}>ตรวจสอบชำระเงิน</option>
                                                    <option value={3}>ส่งใบสมัครสอบ</option>
                                                    <option value={4}>เจ้าหน้าที่ตรวจสอบสำเร็จ</option>
                                                    <option value={5}>แก้ไขข้อมูล</option>
                                                    <option value={6}>มีสิทธ์สอบ</option>
                                                    <option value={7}>ไม่มีสิทธ์สอบ</option>
                                                    <option value={8}>ผ่านการสอบคัดเลือก</option>
                                                    <option value={9}>ไม่ผ่านการสอบคัดเลือก</option>
                                                    <option value={11}>ตรวจสอบชำระเงิน / ส่งใบสมัครสอบ</option>
                                                </select>
                                            </div>
                                            <div className='col-lg-1 text-end'>
                                                <div className='btn-group'>
                                                    <button type='button' className='btn btn-info text-white' onClick={()=>{_Update();}}>ค้นหา</button>
                                                    <button type='button' className='btn btn-success text-white' onClick={()=>{_Export();}}>Export</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2 mb-2'>
                                            <div className='col-12 table-responseive'>
                                                <table className='table' id="ApplicationFormView">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center textcenter'>เลขประจำตัวผู้สมัครสอบ</th>
                                                            <th className='text-center textleft'>ประเภทบัตรประจำตัว</th>
                                                            <th className='text-center textleft'>เลขบัตรประจำตัว</th>
                                                            <th className='text-center textleft'>ชื่อ-นามสกุล</th>
                                                            <th className='text-center textcenter'>เบอร์โทรติดต่อ</th>
                                                            <th className='text-center textcenter'>ระดับชั้น</th>
                                                            <th className='text-center textleft no-sort'>รอบ</th>
                                                            <th className='text-center textcenter no-sort'>สถานะ</th>
                                                            <th className='text-center textcenter'>วันที่ยื่น</th>
                                                            <th className='text-center textcenter'>วันที่แก้ไข</th>
                                                            <th className='text-center textcenter no-sort'>#</th>
                                                        </tr>
                                                    </thead>
                                                    <DataTable TableID={"ApplicationFormView"} Column={_column} Configs={_configs} ref={_ApplicationFormView} ajax={_ajax} ColumnDefs={_ColumnDefs} />

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>
        </React.Fragment>
        <PDFViewCard ref={_PDFViewCard} />
        <ApplicationViewCard ref={_ApplicationViewCard} reload={()=>{_ApplicationFormView.current?.load();}}/>
        </>
    )
});
export default React.memo(ApplicationView)
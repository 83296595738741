const PrefixName = (PrefixNameID)=>{
    switch(Number(PrefixNameID)){
        case 1 : return 'เด็กชาย';
        case 2 : return 'เด็กหญิง';
        case 3 : return 'นาย';
        case 4 : return 'นางสาว';
        default: return '';
    }
}
const IdentifyTypeName = (IdentifyTypeID)=>{
    switch(Number(IdentifyTypeID)){
        case 1: 
            return 'เลขบัตรประจำตัวประชาชน';
        case 2: 
            return 'เลขบัตรประจำตัวผู้ไม่มีสัญชาติไทย';
        case 3: 
            return 'เลขหนังสือเดินทางผู้สมัคร';
        default : return '-';
    } 
}
export {PrefixName,IdentifyTypeName}
import React,{forwardRef,useImperativeHandle,useEffect, useState, useRef } from 'react';
import { Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import moment from 'moment';
import {DateFormat, DateThaiFormat, GetAPIURL,GetAuthorization, NumberFormat} from '../../Utils/Function';
import {PrefixName} from '../../Utils/Prefix';
import {PDFViewCard} from '../../Components';
import ApplicationForm from './ApplicationForm';
import {ApplicantViewCard} from '../Applicant';
const ApplicationView = forwardRef((props,ref) =>{
    const [_ModalViewState,setModalViewState] = useState(false);
    const [_RegulationID,setRegulationID] = useState(null);
    const [_ApplicationForm,setApplicationForm] = useState(null);
    const [_FileList,setFileList] = useState([]);
    const [_FileCheck,setFileCheck] = useState([]);
    const [_Payment,setPayment] = useState(null);
    const [_ConfirmRemark,setConfirmRemark] = useState(null);
    const _PDFViewCard = useRef();
    const [_ViewMode,setViewMode] = useState(1);
    const [_PaymentStatus,setPaymentStatus] = useState(false);
    useEffect(() => {
        (async () => {
            setViewMode(2);
            setPaymentStatus(false);
        })();

        return ()=>{ 
        }
    }, []);
    const _LoadApplicationForm = async(RegulationID)=>{
        setFileList([]);
        setFileCheck([]);
       // Swal.showLoading();
        API({method : "get",url:"/Application/"+RegulationID}).then(res=>{
            
            if(res.data.Status){
                const _obj = res.data.Response;
                setApplicationForm(_obj);
                if(_obj.Payment) setViewMode(3);
                if(_obj.CheckStatus === 1) setViewMode(2);
                //Swal.close();
                setModalViewState(true);
                _LoadApplicationFile(RegulationID);
            }else{
                Swal.close();
            }
        }).catch(error=>{
            Swal.close();
        });
    }
    useImperativeHandle(ref, () => ({
        Show(RegulationID){
            setRegulationID(RegulationID);
            _LoadApplicationForm(RegulationID);
            _LoadApplicationPayment(RegulationID);
        }
      }));
    const _LoadApplicationFile = async(RegulationID)=>{

        API({method : "get",url:"/Application/"+RegulationID+'/File'}).then(res=>{
            if(res.data.Status){
                setFileList(res.data.Response);
                let _tmpList = [];
                res.data.Response.map((v,i)=>{
                    v.Files.map((f,fi)=>{
                        _tmpList.push(f);
                    });
                });
                setFileCheck(_tmpList);
            }
        }).catch(error=>{

        });
        
    }
    const _LoadApplicationPayment = async(RegulationID)=>{

        API({method : "get",url:"/Staff/Application/"+RegulationID+'/Payment'}).then(res=>{
            if(res.data.Status){
                setPayment(res.data.Response);
                if(res.data.Response.PaymentStatus === 1) {
                    setPaymentStatus(true);
                    setViewMode(1);
                }
                if(res.data.Response.PaymentStatus === 2) {
                    setPaymentStatus(true);
                }
            }
        }).catch(error=>{

        });
        
    }
    const _PaymentConfirm = async()=>{

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการยืนยันชำระเงินหรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : "post",url:"/Staff/Application/"+_RegulationID+'/Payment'}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setPayment(res.data.Response);
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                });
                _Reload();
            }).catch(res=>{
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        });

        
    }
    const _PaymentReset = async()=>{

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการยกเลิกชำระเงินหรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : "delete",url:"/Staff/Application/"+_RegulationID+'/Payment'}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setPayment(res.data.Response);
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                });
                _Reload();
            }).catch(res=>{
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        });

        
    }
    const _getFileObj = (FileID) =>{
        let _obj = null;
        _FileCheck.map((v,i)=>{
            if(v.FileID === FileID) _obj = v;
        });
        return _obj;
    }
    const _UpdateValue =(obj)=>{
        let _tmpAR = [];
        _FileCheck.map((v,i)=>{
            if(v.FileID === obj.FileID){
                _tmpAR.push(obj);
            }else{
                _tmpAR.push(v);
            }
        });
        setFileCheck(_tmpAR);
    }
    const _FileCheckBTN = (obj,action)=>{
        if(_ApplicationForm != null && _ApplicationForm.CheckStatus != 0) return;
        let _tmpAR = [];
        _FileCheck.map((v,i)=>{
            if(v.FileID === obj.FileID){
                obj.CheckStatus = action;
                _tmpAR.push(obj);
            }else{
                _tmpAR.push(v);
            }
        });
        setFileCheck(_tmpAR);
    }
    const _SaveConfirm = async(action) =>{
        if(_ApplicationForm != null && _ApplicationForm.CheckStatus != 0) return;

        if(action === 2 && (_ConfirmRemark === null || _ConfirmRemark === undefined || String(_ConfirmRemark).length === 0)){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุหมายเหตุ'
            });
            return;
        }
        let _Error=false;
        _FileCheck.map((v,i)=>{
            if(v.CheckStatus === 2 && v.CheckRemark.length === 0) _Error = true;
            if(v.CheckStatus === 0) _Error = true;
        });
        if(_Error){
            Swal.fire({
                icon: 'error',
                title: 'ตรวจสอบเอกสารไม่ครบ'
            });
            return;
        }
        let _reqObj = {
            CheckStatus : action,
            Remark : _ConfirmRemark,
            Files : _FileCheck
        }
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึกผลการตรวจสอบหรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : "post",url:"/Staff/Application/"+_RegulationID+'/Check',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setApplicationForm(res.data.Response);
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                });
                _Reload();
            }).catch(res=>{
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        });
    }

    const _Reload = ()=>{
        if(props.reload != undefined){
            try{
                props.reload();
            }catch(e){
                console.log(e);
            }
        }
    }
    return (
        <>
        <Modal isOpen={_ModalViewState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">รายละเอียดใบสมัครสอบ</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalViewState(false)}}></button>
            </div>
            <div className='modal-body' style={{minHeight:'300px'}}>
                <div className='row'>
                    <div className='col-lg-10'>
                        <div className='row'>
                            <div className='col-12 mt-1 mb-4 text-center d-block d-sm-block d-md-none'>
                                <img src={GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/Image'} style={{maxWidth:'150px',maxHeight:'200px'}} />
                            </div>
                            {(_ApplicationForm != null &&_ApplicationForm.CandidateCode != undefined && _ApplicationForm.CandidateCode != null ?
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">หมายเลขประจำตัวผู้สมัคร</label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.CandidateCode:'')}</div>
                            </div>
                            :'')}
                            
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ชื่อ - นามสกุล</label>
                                <div className='form-inline'>{(_ApplicationForm != null ? PrefixName(_ApplicationForm.PrefixNameID)+_ApplicationForm.FirstName+' '+_ApplicationForm.LastName:'')}</div>
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">หมายเลขโทรศัพท์</label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.Mobile:'')}</div>
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ระดับชั้น</label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.RegTypeName:'')}</div>
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">สถานะ</label>
                                <div className='form-inline text-primary fw-bold'>{(_ApplicationForm != null ? _ApplicationForm.StatusName:'')}</div>
                                
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ใบสมัครสอบ </label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.RegRoundName:'')}</div>
                            </div>

                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ประเภทโครงการ </label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.ProjectName:'')}</div>
                            </div>

                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ยื่นใบสมัคร </label>
                                <div className='form-inline'>{(_ApplicationForm != null && _ApplicationForm.Status > 0 ? DateThaiFormat(_ApplicationForm.SubmitDate,true):'-')}</div>
                            </div>

                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ชำระค่าธรรมเนียม </label>
                                <div className='form-inline'>{(_Payment != null ? DateThaiFormat(_Payment.PaymentDate,true):'-')}</div>
                            </div>

                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ตรวจสอบเอกสาร </label>
                                <div className='form-inline'>{(_ApplicationForm != null ? DateThaiFormat(_ApplicationForm.CheckDate,true):'-')}</div>
                            </div>
                            <div className='mt-2 mb-2 col-12 text-center d-block d-sm-block d-md-none'>
                                <div className='btn-group btn-group-sm'>
                                    <a className='btn btn-info btn-sm text-white form-control' href={GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/PDF'} target='_blank'>ดาว์โหลดใบสมัคร</a>
                                    {(_ApplicationForm != null &&_ApplicationForm.CandidateCode != undefined && _ApplicationForm.CandidateCode != null ?
                                        <a className='btn btn-warning btn-sm text-white form-control' href={GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/Candidate'} target='_blank'>บัตรประจำตัวผู้สมัคร</a>
                                    :'')}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-2 text-center d-none d-sm-none d-md-block'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <img className='form-control' src={GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/Image'} style={{maxWidth:'250px',maxHeight:'200px'}} />
                            </div>
                        </div>
                        <div className='row mt-4 mb-2'>
                            <div className='col-12 text-center'>
                                <div className='btn-group btn-group-vertical'>
                                <a className='btn btn-info btn-sm text-white form-control' href={GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/PDF'} target='_blank'>ดาว์โหลดใบสมัคร</a>
                                {(_ApplicationForm != null &&_ApplicationForm.CandidateCode != undefined && _ApplicationForm.CandidateCode != null ?
                                    <a className='btn btn-warning btn-sm text-white form-control' href={GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/Candidate'} target='_blank'>บัตรประจำตัวผู้สมัคร</a>
                                    :'')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-3 mt-3'>
                    <div className='col-12'>
                        <div className='btn-group btn-group-sm'>
                            <button type='button' className={(_PaymentStatus ? 'btn btn-primary':'btn btn-secondary')} disabled={(_ViewMode === 1 || !_PaymentStatus ? true:false)} onClick={()=>{setViewMode(1);}}>ตรวจสอบการชำระเงิน</button>
                            <button type='button' className='btn btn-primary text-white' disabled={(_ViewMode === 2 ? true:false)} onClick={()=>{setViewMode(2);}}>ข้อมูลใบสมัคร</button>
                            <button type='button' className='btn btn-primary text-white' disabled={(_ViewMode === 3 ? true:false)} onClick={()=>{setViewMode(3);}}>ตรวจสอบเอกสาร</button>
                            <button type='button' className='btn btn-primary text-white' disabled={(_ViewMode === 4 ? true:false)} onClick={()=>{setViewMode(4);}}>ข้อมูลลงทะเบียน</button>

                        </div>
                    </div>
                </div>
                {(_ViewMode === 1 && _PaymentStatus ? <>
                    <div className='row mb-5'>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h4 className='card-title'>ตรวจสอบการชำระเงิน</h4>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <a href={GetAPIURL()+'/Staff/Application/'+_RegulationID+'/'+GetAuthorization()+'/Payment'} target='_blank'>
                                                <img className='form-control' src={GetAPIURL()+'/Staff/Application/'+_RegulationID+'/'+GetAuthorization()+'/Payment'} />
                                            </a>
                                        </div>
                                        <div className='col-4 text-center'>
                                            <div className='btn btn-group btn-group-lg'>
                                                <button className='btn btn-success btn-lg align-middle' disabled={(_Payment != null && _Payment.PaymentStatus != 1? true:false)} onClick={()=>{_PaymentConfirm();}}>ยืนยัน</button>
                                                <button className='btn btn-danger btn-lg align-middle' onClick={()=>{_PaymentReset();}}>Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>:'')}
                {(_ViewMode === 2 && _ApplicationForm != null ? <>
                    <ApplicationForm Profile={_ApplicationForm}/>
                </>:'')}
                {(_ViewMode === 3 ? <>

                    <div className='row mt-2 mb-2'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='card-title'>เอกสารไฟล์แนบ</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                {_FileList.map((v,i)=>(
                                    <>
                                    <div className='row' key={i}>
                                        <div className='col-lg-12 col-8 fw-semibold mt-3 mb-2'>{v.FileRequetName}</div>
                                    </div>
                                    {v.Files.map((fv,fi)=>(
                                    <>
                                    <div className='card mt-2 mb-2'>
                                        <div className='card-body'>
                                            <div className='row mt-2'>
                                                <div className='col-lg-2 col-12 text-center mt-3 mb-3'>
                                                    <a href={GetAPIURL()+'/File/Application/'+GetAuthorization()+'/'+fv.FileID} target='_blank'>
                                                        <img src={GetAPIURL()+'/File/Application/'+GetAuthorization()+'/'+fv.FileID+'/Preview'} style={{'maxHeight':'150px'}}/>
                                                    </a>
                                                </div>
                                                <div className='col-lg-8 col-12 text-center fw-semibold mt-3 mb-3'>{fv.FileName}</div>
                                                <div className='col-lg-2 col-12 text-center mt-3 mb-3'>
                                                    <div className='btn-group btn-group-sm'>
                                                        <button type='button' className='btn btn-sm btn-success text-white' style={{width:'60px'}} disabled={(_getFileObj(fv.FileID).CheckStatus === 1 ? true : false)} onClick={()=>{_FileCheckBTN(fv,1);}}>ผ่าน</button>
                                                        <button type='button' className='btn btn-sm btn-danger text-white' style={{width:'60px'}} disabled={(_getFileObj(fv.FileID).CheckStatus === 2 ? true : false)} onClick={()=>{_FileCheckBTN(fv,2);}}>แก้ไข</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-12'>
                                                    <textarea className='form-control' value={_getFileObj(fv.FileID).CheckRemark} disabled={(_ApplicationForm != null && _ApplicationForm.CheckStatus != 0 ?true:false)} onChange={(e)=>{fv.CheckRemark = e.target.value;_UpdateValue(fv);}}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    ))}
                                    <hr className='mt-4 mb-4'/>
                                    </>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-4 mb-2'>
                    <div className='col-lg-10'></div>
                    <div className='col-lg-2 text-center'>
                        <div className='btn-group btn-group-sm'>
                            <button type='button' className='btn btn-sm btn-success text-white' style={{width:'60px'}} disabled={(_ApplicationForm != null && _ApplicationForm.CheckStatus ===1 ?true:false)} onClick={()=>{_SaveConfirm(1);}}>ผ่าน</button>
                            <button type='button' className='btn btn-sm btn-danger text-white' style={{width:'60px'}} disabled={(_ApplicationForm != null && _ApplicationForm.CheckStatus === 2 ?true:false)} onClick={()=>{_SaveConfirm(2);}}>แก้ไข</button>
                        </div>
                    </div>
                </div>

                <div className='row mt-2 mb-2'>
                    <div className='col-lg-12'>
                        <textarea className='form-control' value={_ConfirmRemark} disabled={(_ApplicationForm != null && _ApplicationForm.CheckStatus != 0 ?true:false)} onChange={(e)=>{setConfirmRemark(e.target.value);}}></textarea>
                    </div>
                </div>
                </>:'')}

                {(_ViewMode === 4 && _ApplicationForm != null ? <>
                    <ApplicantViewCard ApplicantID={_ApplicationForm.ApplicantID}/>
                </>:'')}
            </div>
        </Modal>
        <PDFViewCard ref={_PDFViewCard}/>
        </>
      )
});

export default React.memo(ApplicationView)
import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { PrefixName,IdentifyTypeName } from '../../Utils/Prefix';
import { DateThaiFormat } from '../../Utils/Function';
import API from '../../API';

const ApplicantView = forwardRef((props,ref) =>{
    //const _ApplicantID = props.ApplicantID;
    const [_Detail,setDetail] = useState(null);
    useEffect(() => {
        (async () => {
            setDetail(null);
            _LoadApplicant(props.ApplicantID);
        })();

        return ()=>{ }
    }, []);
    const _LoadApplicant = async(ApplicantID)=>{
        setDetail(null);
        API({method : "get",url:"/Staff/Applicant/"+ApplicantID}).then(res=>{
            console.log(res.data);
            if(res.data.Status){
                setDetail(res.data.Response);
            }
            
        }).catch(error=>{

        });
    }
    if(_Detail != null){
        return (
            <>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-lg-2'>
                                        <label className="form-label fw-semibold">คำนำหน้าชื่อ</label>
                                        <div className='form-control text-center'>{PrefixName(_Detail.PrefixNameID)}</div>
                                    </div>
                                    <div className='col-lg-5'>
                                        <label className="form-label fw-semibold">ชื่อ</label>
                                        <div className='form-control text-first'>{_Detail.FirstName}</div>

                                    </div>
                                    <div className=' col-lg-5'>
                                        <label className="form-label fw-semibold">นามสกุล</label>
                                        <div className='form-control text-first'>{_Detail.LastName}</div>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-2'>
                                        <label className="form-label fw-semibold">วันเดือนปีเกิด</label>
                                        <div className='form-control text-center'>{DateThaiFormat(_Detail.BirthDay)}</div>
                                    </div>
                                    <div className='col-3'>
                                        <label className="form-label fw-semibold">{IdentifyTypeName(_Detail.IdentifyType)}</label>
                                        <div className='form-control text-center'>{_Detail.IdentifyID}</div>
                                    </div>
                                </div>
                                
                                <div className='row mt-2 mb-2'>
                                    <div className='col-4'>
                                        <label className="form-label fw-semibold">หมายเลขโทรศัพท์</label>
                                        <div className='form-control'>{_Detail.MobileNumber}</div>
                                    </div>
                                    <div className='col-5'>
                                        <label className="form-label fw-semibold">Email</label>
                                        <div className='form-control'>{_Detail.Email}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    
});

export default React.memo(ApplicantView)